$color-primary: #7FC0B5;
$color-secondary: #e8f5fb;

$color-danger: #f44336;
$color-warning: #f6ae02;
$color-confirm: #169c00;
$color-text: #212121;
$color-text-secondary: #9B9B9B;
$color-text-black: #2C3032;
$color-link: #7FC0B5;
$color-white: #fff;
$color-grey: #C4C4C4;

$border-input-color: #263238;
$border-input-color-hover: #263238;

//breakpoints
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;
