@import 'partials/variables';
@import 'partials/normalize';

body {
	margin: 0;
	font-family: 'Lato', sans-serif, '-apple-system, BlinkMacSystemFont', 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;
	color: $color-text;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

li {
	list-style-type: none;
}

p {
	margin: 0;
}

a {
	text-decoration: none;
}

button {
	outline: none;
}

button.Mui-selected {
	font-weight: 500;
	border-bottom: 2px solid #000;
}

input:-internal-autofill-selected {
	appearance: menulist-button;
	background-color: #fff !important;
	background-image: none !important;
	color: -internal-light-dark(black, white) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

::placeholder {
	color: #2e466e;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #2e466e;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #2e466e;
}
