@import 'variables';

.logo {
	&-main {
		display: block;
		flex-shrink: 0;
		max-width: 192px;
	}
}

.icon {
	width: 1rem;
	fill: currentColor;

	&-bordered {
		padding: 3px;
		border: 1px solid $border-input-color;
		border-radius: 50%;
	}

	&-round {
		font-size: 20px;
		border-radius: 20px;
		background-color: #ffffff;
	}
}
