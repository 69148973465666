@import 'variables';

@font-face {
	font-family: 'Lato';
	font-weight: 300;
	src: url('../../assets/fonts/Lato/Lato-Light.ttf') format('truetype');
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	font-weight: normal;
	src: url('../../assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	font-weight: 600;
	src: url('../../assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
	font-display: swap;
	font-style: normal;
}

.color {
	&-primary {
		color: $color-primary;
	}
	&-warning {
		color: $color-warning;
	}
	&-danger {
		color: $color-danger;
	}
	&-confirm {
		color: $color-confirm;
	}
}

.title {
	@extend .color-primary;
	margin: 5px 0;
}

.subtitle {
	letter-spacing: 0.15px;
	font-size: 18px !important;
	margin: 5px 0;
	font-weight: 600 !important;
	line-height: 22px !important;


	&--large {
		font-size: 24px !important;
		font-weight: 600;
		line-height: 29px !important;
	}
	&--small {
		font-size: 16px !important;
		font-weight: normal;
	}
}

.text {
	letter-spacing: 0.15px;
	&-medium {
		font-size: 14px !important;
		font-weight: 400 !important;
	}
	&-small {
		font-size: 12px !important;
	}
}
