@import 'src/styles/partials/variables';

.intl-tel-input {
	width: 100%;

	::placeholder {
		color: #666666;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #666666;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #666666;
	}

	.phone-input {
		width: 100%;
		border-radius: 3px;
		font-size: 1rem;
		padding: 8px 16px;
		border: 1px solid $color-grey;
		height: 52px;

		&:hover {
			border: 1px solid $color-primary;
		}

		&:focus {
			border: 1px solid $color-primary;
			box-shadow: 0 0 0 1px $color-primary;
			outline: none;
		}
	}

	&.allow-dropdown.separate-dial-code {
		.selected-dial-code {
			padding-left: 0;
		}
	}

	.invalid {
		border: 1px solid $color-danger;
	}
}

.iti-container {
	z-index: 10000 !important;
}


.selected-flag{
	display: flex !important;
}

.iti-flag{
	margin-right: 4px;
}
.selected-dial-code{
	font-size: 14px;
}