@import './partials/variables';
@import './partials/fonts';
@import './partials/icons';
@import './partials/forms';

.container {
	&-app {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	&-external {
		padding: 20px 16px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-x: hidden;
		background: $color-white;
	}

	&-internal {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background-color: $color-white;
		height: 100%;
	}
}

.pointer {
	cursor: pointer;
}

.error-text {
	color: $color-danger;
	font-size: 0.75rem;
	margin: 3px 12px 0;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	text-align: left;

	&--centered {
		text-align: center;
	}

	&--mt {
		margin-top: 1rem;
	}
}

.link {
	color: $color-link;
	text-decoration: none;
	font-weight: 400;
}

.profile-link{
	height: 26px;
	margin-top: 12px;
	margin-bottom: 12px;
	border-bottom: 1px solid #F9F9F9;
	cursor: pointer;
}

.profile-link-text {
	color: $color-text-black;
	text-decoration: none;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 16px !important;
}

.textPrimary{
	color: $color-primary !important;
}

.textSecondary{
	color: $color-text-secondary !important;
}
.textBlack{
	color: $color-text-black !important;
}

.slide-to-left {
	animation: slideToLeft 0.3s ease-in-out forwards;
}

@keyframes slideToLeft {
	from {
		transform: translateX(100vw);
	}
	to {
		transform: translateX(0);
	}
}

.slide-to-right {
	animation: slideToRight 0.3s ease-in-out forwards;
}

@keyframes slideToRight {
	from {
		transform: translateX(-100vw);
	}
	to {
		transform: translateX(0);
	}
}

.overflow {
	overflow-y: auto;
	padding-bottom: 18px;
}

.flexGrow {
	flex-grow: 1;
}

.max-width{
	width: 100%;
}

.max-form-width{
	max-width: 576px;
}

.center-horizontal{
	margin: 0 auto;
}

.capitalizeText{
	text-transform: capitalize !important;
}

.textInitial{
	text-transform: initial !important;
}
